/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

$main-colour: #56325c;
$second-colour: #fcb040;
$third-colour: #edbb69;

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@font-face {
  font-family: 'Abrade';
  src: url('/assets/fonts/abrade.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/abrade.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/abrade.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/abrade.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/abrade.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@import 'common';
@import 'grids';
@import 'bourbon';

* {
	  box-sizing: border-box;
}

.row {
	margin: 0 !important;
}

@mixin afterYoFloat {
	&:after {
	    content: '';
	    display: block;
	    clear: both;
	}
}

body {
	font-family: 'Futura', Helvetica, Arial, sans-serif;
	font-weight: 400;
	background: #fff;
}


.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 150px;
	background: #000;
	color: #fff;

	@media all and (max-width: 800px) {
		height: 250px;
	}

	.logo {
		position: absolute;
		display: block;
		margin: 0 auto;
		left: 50%;
		margin-left: -139.5px;
		img {
			height: 130px;
		}
	}

	.call-me {
		position: absolute;
		right: 50px;
		text-align: right;
		margin-top: 50px;

		@media all and (max-width: 800px) {
			left: 0px;
			right: 0px;
			text-align: center;

			margin-top: 150px;
		}

		.call {
			font-size: 30px;
			font-weight: 700;
			
			a {
				color: #fff;
				text-decoration: none;
			}
		}

		.email {
			font-variant: small-caps; 
			font-size: 19px;
			font-weight: 700;

			a {
				color: #fff;
				text-decoration: none;
			}
		}
	}
}

.navigation {
	position: fixed;
	top: 150px;
	left: 0;
	right: 0;
	height: 50px;
	line-height: 50px;
	background: #464646;
	text-align: center;

	@media all and (max-width: 800px) {
		display: none;
	}

	ul {
		li {
			display: inline-block;

			a {
				display: block;
				color: #fff;
				font-style: italic;
				font-size: 24px;
				text-decoration: none;
				padding: 0 20px;

				&:hover {
					background: #666;
				}
			}
		}
	}
}

.page-content {
	margin-top: 190px;

	@media all and (max-width: 800px) {
		margin-top: 250px;
	}

	.gif-area {
		max-width: 600px;
		margin: 0 auto;
		margin-top: 50px;
	}
}


.blacktie-footer {
    padding: 10px;
    background: #111;
    text-align: center;
    color: #fff;
    font-weight: 100;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    letter-spacing: 2px;
    display: block;
}